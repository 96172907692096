<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/actions-update-status.svg')" />
          <span class="t">Update Status</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span class="action-title">STATUS</span>
        </div>

        <span class="action-description">
          Automatically set and update the status for a single entry in the
          current submitted form or reference an entry in another form.
        </span>

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12">
              <v-select
                outlined
                v-model="selectedEntryID"
                color="primary"
                label="Entry ID"
                hide-details="auto"
                placeholder="Entry ID"
                :items="inputs.fields"
                item-text="label"
                item-value="key"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                v-model="selectedStatus"
                color="primary"
                label="Status"
                hide-details="auto"
                placeholder="Select Status"
                :items="statuses"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                color="primary"
                label="Form"
                v-model="selectedForm"
                :items="allForms"
                item-text="form_title"
                item-value="id"
                hide-details="auto"
                placeholder="Form"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            @click="addToWorkflow"
            :disabled="!canAddToWorkflow"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenUpdateStatus",
        properties: {
          keys: ["entry", "status", "form", "organization"],
          values: ["", "", ""],
        },
      },
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },
    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      selectedEntryID: "",
      selectedTags: "",
      statuses: [
        { name: "Open", value: "open" },
        { name: "Closed", value: "closed" },
        { name: "Approved", value: "approved" },
        { name: "Rejected", value: "rejected" },
      ],
      selectedStatus: "",
      selectedForm: "",
    };
  },
  mounted() {
    this.mapForm();
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenUpdateStatus",
        properties: {
          keys: ["entry", "status", "form", "organization"],
          values: [
            this.selectedEntryID.key === undefined
              ? "@" + this.selectedEntryID
              : "@" + this.selectedEntryID.key,
            this.selectedStatus,
            this.selectedForm.toString(),
            this.organizationId,
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();

      this.close();
    },

    mapForm() {
      var entry =
        this.value.properties.values[
          this.value.properties.keys.indexOf("entry")
        ] || "";

      if (entry.indexOf("@") === 0) {
        entry = entry.slice(1);
        // get the object from the inputs
        const input = this.inputs.fields.find((i) => i.key === entry);
        if (input) {
          this.selectedEntryID = input;
        } else {
          this.selectedEntryID = entry;
        }
      } else {
        this.selectedEntryID = entry;
      }

      this.selectedStatus =
        this.value.properties.values[
          this.value.properties.keys.indexOf("status")
        ];

      this.selectedForm = parseInt(
        this.value.properties.values[this.value.properties.keys.indexOf("form")]
      );

      this.sendOutChannel();
    },

    sendOutChannel() {
      this.$emit("channel", this.selectedStatus?.toUpperCase());
    },
  },
  computed: {
    canAddToWorkflow() {
      return (
        this.selectedEntryID !== "" &&
        this.selectedStatus !== "" &&
        this.selectedForm !== ""
      );
    },
    allForms() {
      return this.$store.getters["formBuilder/forms"] || [];
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
