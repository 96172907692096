<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img
            class="i"
            :src="require('@/assets/actions-update-customer.svg')"
          />
          <span class="t">Create Customer</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span
            class="action-title"
            :class="{ active: tab === 'customer' }"
            @click="tab = 'customer'"
            >CUSTOMER</span
          >
          <span
            v-if="hasAddon"
            class="action-title"
            :class="{ active: tab === 'addon' }"
            @click="tab = 'addon'"
            >ADD-ON</span
          >
        </div>

        <span class="action-description">
          <template v-if="tab === 'customer'">
            Add a new customer to your records. <br />
            Customers will be issued a NUBAN for all subsequent invoices and
            payments to your organization.
          </template>
          <template v-if="tab === 'addon'">
            Add-on allows you create this same customer on a third-party
            platform outside of hyphen. Select from the list of
            <b>connected</b> platforms below
          </template>
        </span>

        <div class="a-wrapper">
          <template v-if="tab === 'customer'">
            <v-row>
              <v-col cols="12">
                <v-select
                  outlined
                  v-model="form.type"
                  color="primary"
                  label="Customer Type"
                  :items="customerType"
                  hide-details="auto"
                  placeholder="Customer Type"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-combobox
                  v-model="form.firstname"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="First Name"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.lastname"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Last Name"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-combobox
                  v-model="form.email"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'email' || i.type === 'input'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Email"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.phone"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter((i) => i.type === 'digits')) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Phone"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row v-if="form.type === 'Company'">
              <v-col cols="12">
                <v-combobox
                  v-model="form.company"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'text' || i.type === 'select'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Company Name"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="hasAddon"
                  color="success"
                  label="Enable Addon"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-if="tab === 'addon'">
            <v-row>
              <v-col cols="12">
                <v-select
                  outlined
                  v-model="form.addon"
                  color="primary"
                  label="Platform"
                  :items="platforms"
                  hide-details="auto"
                  placeholder="Select Platform"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-combobox
                  v-model="form.bvn"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter BVN"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.nin"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter NIN"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-combobox
                  v-model="form.date_of_birth"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter Date of Birth"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.gender"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter Gender"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="form.create"
                  color="success"
                  hide-details="auto"
                  label="Create Customer"
                ></v-switch>
                <v-switch
                  v-model="form.activate"
                  color="success"
                  hide-details="auto"
                  label="Activate Customer"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            @click="addToWorkflow"
            :disabled="!canAddToWorkflow"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenCreateCustomer",
        properties: {
          keys: [
            "organization",
            "type",
            "firstname",
            "lastname",
            "email",
            "phone",
            "company",
            "bvn",
            "nin",
            "date_of_birth",
            "gender",
            "create",
            "activate",
            "addon",
          ],
          values: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        },
      },
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      customerType: ["Retail", "Company"],
      platforms: ["woodcore"],
      tab: "customer",
      form: {
        type: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        bvn: "",
        nin: "",
        date_of_birth: "",
        gender: "",
        activate: false,
        create: false,
        addon: "",
      },
      hasAddon: false,
    };
  },
  mounted() {
    this.mapForm();
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      if (!this.hasAddon) {
        this.form.bvn = "";
        this.form.nin = "";
        this.form.addon = "";
        this.form.create = false;
        this.form.activate = false;
      }

      const payload = {
        type: "hyphenCreateCustomer",
        properties: {
          keys: [
            "organization",
            "type",
            "firstname",
            "lastname",
            "email",
            "phone",
            "company",
            "bvn",
            "nin",
            "date_of_birth",
            "gender",
            "create",
            "activate",
            "addon",
          ],
          values: [
            this.organizationId,
            this.form.type,
            this.form.firstname.key !== undefined
              ? "@" + this.form.firstname.key
              : this.form.firstname,
            this.form.lastname.key !== undefined
              ? "@" + this.form.lastname.key
              : this.form.lastname,
            this.form.email.key !== undefined
              ? "@" + this.form.email.key
              : this.form.email,
            this.form.phone.key !== undefined
              ? "@" + this.form.phone.key
              : this.form.phone,
            this.form.company.key !== undefined
              ? "@" + this.form.company.key
              : this.form.company,
            this.form.bvn.key !== undefined
              ? "@" + this.form.bvn.key
              : this.form.bvn,
            this.form.nin.key !== undefined
              ? "@" + this.form.nin.key
              : this.form.date_of_birth,
            this.form.date_of_birth.key !== undefined
              ? "@" + this.form.date_of_birth.key
              : this.form.date_of_birth,
            this.form.gender.key !== undefined
              ? "@" + this.form.gender.key
              : this.form.gender,
            this.form.create.toString(),
            this.form.activate.toString(),
            this.form.addon,
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();

      this.close();
    },

    mapForm() {
      if (this.value && !this.value?.fresh) {
        const type =
          this.value.properties.values[
            this.value.properties.keys.indexOf("type")
          ] || "";

        this.form.type = type;

        // first name field
        var firstname =
          this.value.properties.values[
            this.value.properties.keys.indexOf("firstname")
          ] || "";

        if (firstname.indexOf("@") === 0) {
          firstname = firstname.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === firstname);
          if (input) {
            this.form.firstname = input;
          } else {
            this.form.firstname = firstname;
          }
        } else {
          this.form.firstname = firstname;
        }

        // last name field
        var lastname =
          this.value.properties.values[
            this.value.properties.keys.indexOf("lastname")
          ] || "";

        if (lastname.indexOf("@") === 0) {
          lastname = lastname.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === lastname);
          if (input) {
            this.form.lastname = input;
          } else {
            this.form.lastname = lastname;
          }
        } else {
          this.form.lastname = lastname;
        }

        // email field
        var email =
          this.value.properties.values[
            this.value.properties.keys.indexOf("email")
          ] || "";

        if (email.indexOf("@") === 0) {
          email = email.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === email);
          if (input) {
            this.form.email = input;
          } else {
            this.form.email = email;
          }
        } else {
          this.form.email = email;
        }

        // phone field
        var phone =
          this.value.properties.values[
            this.value.properties.keys.indexOf("phone")
          ] || "";

        if (phone.indexOf("@") === 0) {
          phone = phone.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === phone);
          if (input) {
            this.form.phone = input;
          } else {
            this.form.phone = phone;
          }
        } else {
          this.form.phone = phone;
        }

        // company field
        var company =
          this.value.properties.values[
            this.value.properties.keys.indexOf("company")
          ] || "";

        if (company.indexOf("@") === 0) {
          company = company.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === company);
          if (input) {
            this.form.company = input;
          } else {
            this.form.company = company;
          }
        } else {
          this.form.company = company;
        }

        // bvn field
        var bvn =
          this.value.properties.values[
            this.value.properties.keys.indexOf("bvn")
          ] || "";

        if (bvn.indexOf("@") === 0) {
          bvn = bvn.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === bvn);
          if (input) {
            this.form.bvn = input;
          } else {
            this.form.bvn = bvn;
          }
        } else {
          this.form.bvn = bvn;
        }

        // nin field
        var nin =
          this.value.properties.values[
            this.value.properties.keys.indexOf("nin")
          ] || "";

        if (nin.indexOf("@") === 0) {
          nin = nin.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === nin);
          if (input) {
            this.form.nin = input;
          } else {
            this.form.nin = nin;
          }
        } else {
          this.form.nin = nin;
        }

        // date of birth field
        var date_of_birth =
          this.value.properties.values[
            this.value.properties.keys.indexOf("date_of_birth")
          ] || "";

        if (date_of_birth.indexOf("@") === 0) {
          date_of_birth = date_of_birth.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === date_of_birth);
          if (input) {
            this.form.date_of_birth = input;
          } else {
            this.form.date_of_birth = date_of_birth;
          }
        } else {
          this.form.date_of_birth = date_of_birth;
        }

        // gender field
        var gender =
          this.value.properties.values[
            this.value.properties.keys.indexOf("gender")
          ] || "";

        if (gender.indexOf("@") === 0) {
          gender = gender.slice(1);
          // get the object from the inputs

          const input = this.inputs.fields.find((i) => i.key === gender);
          if (input) {
            this.form.gender = input;
          } else {
            this.form.gender = gender;
          }
        } else {
          this.form.gender = gender;
        }

        // create field
        this.form.create =
          (this.value.properties.values[
            this.value.properties.keys.indexOf("create")
          ] || "") === "true"
            ? true
            : false;

        // activate field
        this.form.activate =
          (this.value.properties.values[
            this.value.properties.keys.indexOf("activate")
          ] || "") === "true"
            ? true
            : false;

        // addon field
        var addon =
          this.value.properties.values[
            this.value.properties.keys.indexOf("addon")
          ] || "";

        if (addon.indexOf("@") === 0) {
          addon = addon.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === addon);
          if (input) {
            this.form.addon = input;
          } else {
            this.form.addon = addon;
          }
        } else {
          this.form.addon = addon;
        }
      }

      this.hasAddon = this.form.addon !== "";

      this.sendOutChannel();
    },

    sendOutChannel() {
      const [firstname, lastname] = [
        this.form.firstname.key === undefined
          ? this.form.firstname
          : "@" + this.form.firstname.key,
        this.form.lastname.key === undefined
          ? this.form.lastname
          : "@" + this.form.lastname.key,
      ];

      this.$emit("channel", firstname + " " + lastname);
    },
  },
  computed: {
    canAddToWorkflow() {
      var val = null;

      if (this.form.type === "Retail") {
        val = this.form.firstname && this.form.lastname && this.form.email;
      } else {
        val =
          this.form.firstname &&
          this.form.lastname &&
          this.form.email &&
          this.form.company;
      }

      if (this.hasAddon) {
        val =
          val &&
          this.form.addon &&
          this.form.bvn &&
          this.form.nin &&
          this.form.date_of_birth &&
          this.form.gender;
      }

      return val;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },

    form: {
      handler() {
        if (this.form.type === "Retail") {
          this.form.company = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: #8f96a1;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        &.active {
          color: var(--v-primary-base);
          border-bottom: 5px solid var(--v-primary-base);
        }

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;

      b {
        text-decoration: underline;
      }
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
