<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/actions-wallet-money.svg')" />
          <span class="t">Request Loan</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span class="action-title">LOAN CREATION</span>
        </div>

        <span class="action-description"
          >Capture expense request automatically before it is approved and added
          to payables</span
        >

        <div class="a-wrapper">
          <template v-if="step === 1">
            <v-row>
              <v-col cols="6" sm="12">
                <v-combobox
                  label="Loan Type"
                  type="text"
                  v-model="data.loanType"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter loan type"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Requester"
                  type="text"
                  v-model="data.submitted_by"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'email'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter Requester"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Principal"
                  type="text"
                  v-model="data.principal"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number' ||
                          i.type === 'money'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter Principal"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Duration"
                  type="text"
                  v-model="data.duration"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter duration"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Duration by"
                  type="text"
                  v-model="data.durationBy"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter duration by"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>
          </template>

          <template v-if="step === 2">
            <v-row>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Repayment Every"
                  type="text"
                  v-model="data.repaymentEvery"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter repayment every"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>

              <v-col cols="12" sm="6">
                <v-combobox
                  label="Repayment Frequency"
                  type="text"
                  v-model="data.repaymentFrequency"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter repayment frequency"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Interest Type"
                  type="text"
                  v-model="data.interestType"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number' ||
                          i.type === 'money'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter interest type"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>

              <v-col cols="12" sm="6">
                <v-combobox
                  label="Interest Rate"
                  type="text"
                  v-model="data.interestRate"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'money' ||
                          i.type === 'digits' ||
                          i.type === 'number'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter interest rate"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Number of Repayments"
                  type="text"
                  v-model="data.numberOfRepayments"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) =>
                          i.type === 'input' ||
                          i.type === 'text' ||
                          i.type === 'digits' ||
                          i.type === 'number' ||
                          i.type === 'money'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter number of repayments"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-combobox
                  label="Expected Disbursement Date"
                  type="text"
                  v-model="data.expectedDisbursementDate"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Enter expected disbursement date"
                  hide-details="auto"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>
          </template>
        </div>

        <div class="bottom">
          <v-btn
            @click="step === 1 ? close : (step = 1)"
            large
            color="primary"
            outlined
          >
            <v-icon v-if="step === 2" left>mdi-chevron-left</v-icon>
            <v-icon v-else left>mdi-close</v-icon>

            {{ step === 1 ? "Cancel" : "Back" }}
          </v-btn>

          <v-btn
            @click="actnBtn"
            :disabled="step === 1 ? false : !canAddToWorkflow"
            large
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon>
            {{ step === 1 ? "Next" : " Add to FLow" }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenAddLoan",
        properties: {
          keys: [
            "submitted_by",
            "principal",
            "loanType",
            "duration",
            "durationBy",
            "numberOfRepayments",
            "repaymentEvery",
            "repaymentFrequency",
            "interestType",
            "interestRate",
            "expectedDisbursementDate",
            "organization",
          ],
          values: ["", "", "", "", "", "", "", "", "", "", "", ""],
        },
      },
    },

    organizationId: {
      type: String,
      required: true,
    },

    isSDK: {
      type: Boolean,
      default: false,
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      step: 1,
      data: {
        submitted_by: "",
        principal: "",
        loanType: "",
        duration: "",
        durationBy: "",
        numberOfRepayments: "",
        repaymentEvery: "",
        repaymentFrequency: "",
        interestType: "",
        interestRate: "",
        expectedDisbursementDate: "",
      },
    };
  },
  mounted() {
    this.mapForm();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    actnBtn() {
      if (this.step === 1) {
        this.step = 2;
      } else {
        this.addToWorkflow();
      }
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenAddLoan",
        properties: {
          keys: [
            "submitted_by",
            "principal",
            "loanType",
            "duration",
            "durationBy",
            "numberOfRepayments",
            "repaymentEvery",
            "repaymentFrequency",
            "interestType",
            "interestRate",
            "expectedDisbursementDate",
            "organization",
          ],
          values: [
            typeof this.data.submitted_by === "object"
              ? "@" + this.data.submitted_by.key || ""
              : this.data.submitted_by,

            typeof this.data.principal === "object"
              ? "@" + this.data.principal.key || ""
              : this.data.principal,

            typeof this.data.loanType === "object"
              ? "@" + this.data.loanType.key || ""
              : this.data.loanType,

            typeof this.data.duration === "object"
              ? "@" + this.data.duration.key || ""
              : this.data.duration,
            typeof this.data.durationBy === "object"
              ? "@" + this.data.durationBy.key || ""
              : this.data.durationBy,

            typeof this.data.numberOfRepayments === "object"
              ? "@" + this.data.numberOfRepayments.key || ""
              : this.data.numberOfRepayments,

            typeof this.data.repaymentEvery === "object"
              ? "@" + this.data.repaymentEvery.key || ""
              : this.data.repaymentEvery,

            typeof this.data.repaymentFrequency === "object"
              ? "@" + this.data.repaymentFrequency.key || ""
              : this.data.repaymentFrequency,

            typeof this.data.interestType === "object"
              ? "@" + this.data.interestType.key || ""
              : this.data.interestType,

            typeof this.data.interestRate === "object"
              ? "@" + this.data.interestRate.key || ""
              : this.data.interestRate,

            typeof this.data.expectedDisbursementDate === "object"
              ? "@" + this.data.expectedDisbursementDate.key || ""
              : this.data.expectedDisbursementDate,
            this.organizationId,
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();
      this.close();
    },

    mapForm() {
      if (this.value) {
        this.data = {
          submitted_by: this.getData("submitted_by"),
          principal: this.getData("principal"),
          loanType: this.getData("loanType"),
          duration: this.getData("duration"),
          organization: this.getData("organization"),
          durationBy: this.getData("durationBy"),
          numberOfRepayments: this.getData("numberOfRepayments"),
          repaymentEvery: this.getData("repaymentEvery"),
          repaymentFrequency: this.getData("repaymentFrequency"),
          interestType: this.getData("interestType"),
          interestRate: this.getData("interestRate"),
          expectedDisbursementDate: this.getData("expectedDisbursementDate"),
        };

        this.sendOutChannel();
      }
    },

    sendOutChannel() {
      let channel =
        typeof this.data.submitted_by === "object"
          ? this.data.submitted_by.key
          : this.data.submitted_by + " " + typeof this.data.principal ===
            "object"
          ? this.data.principal.key
          : this.data.principal;

      this.$emit("channel", channel);
    },

    getData(key) {
      var val =
        this.value.properties.values[this.value.properties.keys.indexOf(key)] ||
        "";
      if (val.indexOf("@") === 0) {
        val = val.slice(1);
        // get the object from the inputs
        const input = this.inputs.fields.find((i) => i.key === val);
        if (input) {
          return input;
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
  },
  computed: {
    canAddToWorkflow() {
      return (
        this.data.submitted_by &&
        this.data.principal &&
        this.data.loanType &&
        this.data.duration &&
        this.data.durationBy &&
        this.data.numberOfRepayments &&
        this.data.repaymentEvery &&
        this.data.repaymentFrequency &&
        this.data.interestType &&
        this.data.interestRate &&
        this.data.expectedDisbursementDate
      );
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
