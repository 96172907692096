<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img
            class="i"
            :src="require('@/assets/actions-add-to-payables.svg')"
            alt=""
          />
          <span class="t">Add To Payble</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span class="action-title">PAYABLE</span>
        </div>

        <span class="action-description"
          >Create a new payable entry using transaction data within the
          workflow</span
        >

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.invoice_no"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter((i) => i.type === 'number')) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Invoice No"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.amount_due"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'number' || i.type === 'money'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Amount Due"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <p class="form-label py-0 mb-2">Due Date</p>
              <v-menu
                ><template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details="auto"
                    append-icon="mdi-calendar-blank-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="dueDateIndays"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="form.due_date"
                  no-title
                  scrollable
                  :min="minDate"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
            :disabled="!canAddToWorkflow"
            @click="addToWorkflow"
          >
            <v-icon left>mdi-chevron-right</v-icon> SAVE
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenAddToPayables",
        properties: {
          keys: [
            "invoice_no",
            "amount_due",
            "due_date",
            "organization",
            "name",
          ],
          values: [],
        },
      },
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        invoice_no: "",
        amount_due: "",
        due_date: "",
      },
      minDate: new Date().toISOString().slice(0, 10),
    };
  },
  mounted() {
    this.mapForm();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenAddToPayables",
        properties: {
          keys: [
            "invoice_no",
            "amount_due",
            "due_date",
            "organization",
            "name",
          ],
          values: [
            this.form.invoice_no.key === undefined
              ? this.form.invoice_no
              : "@" + this.form.invoice_no.key,
            this.form.amount_due.key === undefined
              ? this.form.amount_due
              : "@" + this.form.amount_due.key,
            this.form.due_date,
            this.organizationId,
            "payables",
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();
      this.close();
    },

    sendOutChannel() {
      this.$emit(
        "channel",
        this.form.amount_due.key === undefined
          ? this.$appCurrencySymbolAndSigns.currencySign + this.form.amount_due
          : "@" + this.form.amount_due.key
      );
    },

    mapForm() {
      if (this.value) {
        // set to
        var invoice_no =
          this.value.properties.values[
            this.value.properties.keys.indexOf("invoice_no")
          ] || "";

        if (invoice_no.indexOf("@") === 0) {
          invoice_no = invoice_no.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === invoice_no);
          if (input) {
            this.form.invoice_no = input;
          } else {
            this.form.invoice_no = invoice_no;
          }
        } else {
          this.form.invoice_no = invoice_no;
        }

        var amount_due =
          this.value.properties.values[
            this.value.properties.keys.indexOf("amount_due")
          ] || "";

        if (amount_due.indexOf("@") === 0) {
          amount_due = amount_due.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === amount_due);
          if (input) {
            this.form.amount_due = input;
          } else {
            this.form.amount_due = amount_due;
          }
        } else {
          this.form.amount_due = amount_due;
        }

        this.form.due_date =
          this.value.properties.values[
            this.value.properties.keys.indexOf("due_date")
          ];

        this.sendOutChannel();
      }
    },
  },

  computed: {
    canAddToWorkflow() {
      return this.form.invoice_no && this.form.amount_due && this.form.due_date;
    },

    //get the difference between two dates in days using moment.js
    dueDateIndays() {
      if (!this.form.due_date) return "";
      let given = this.$moment(this.form.due_date, "YYYY-MM-DD");
      let current = this.$moment().startOf("day");
      return this.$moment.duration(given.diff(current)).asDays();
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
