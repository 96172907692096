import { render, staticRenderFns } from "./form-trigger.vue?vue&type=template&id=24b4044c&scoped=true&"
import script from "./form-trigger.vue?vue&type=script&lang=js&"
export * from "./form-trigger.vue?vue&type=script&lang=js&"
import style0 from "./form-trigger.vue?vue&type=style&index=0&id=24b4044c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b4044c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VIcon,VProgressCircular,VSelect})
