<template>
  <div>
    <v-row class="mx-2 pl-4" style="align-items: center" no-gutters>
      <v-col cols="12" md="4">
        <v-select
          v-model="form.field"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="inputs.fields"
          item-text="label"
          item-value="key"
          style="
            background: #ffffff;
            box-sizing: border-box;
            border-radius: 3px;
          "
          class="justify-center my-2 mr-2"
          flat
          outlined
          hide-details="auto"
          placeholder="Select a field"
          @change="() => (form.target = '')"
        >
          <template slot="append">
            <v-icon class="pl-2">mdi-menu-down</v-icon>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          color="#96EAD"
          v-model="form.type"
          id="operators"
          item-value="key"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="inputs.operators"
          item-text="label"
          class="justify-center my-2 mr-2"
          flat
          outlined
          hide-details="auto"
          style="box-sizing: border-box; border-radius: 3px"
          :style="isSDK ? 'background: #F9FAFA' : 'background:  #e8f9f6'"
        >
          <template slot="append">
            <v-icon class="pl-2">mdi-menu-down</v-icon>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="3">
        <template v-if="target">
          <v-select
            v-if="
              (target.type && target.type === 'dropDown') ||
              target.type === 'radio' ||
              target.type === 'checkbox' ||
              (target.type && target.type === 'select') ||
              (target && target.type === 'checklist') ||
              (target && target.type === 'checkgroup')
            "
            v-model="form.target"
            :items="target.options"
            item-text="label"
            item-value="value"
            max-height="60px"
            height="60px"
            :placeholder="target.label || ''"
            hide-details="auto"
            class="mr-2 my-2"
            outlined
            :disabled="disableTarget"
          >
          </v-select>
          <!-- :multiple="target.type === 'dropDown' || target.type === 'checkbox'" -->

          <v-text-field
            v-else-if="target.type && target.type === 'text'"
            v-model.trim="form.target"
            :placeholder="target.label || ''"
            type="text"
            hide-details="auto"
            class="mr-2 my-2"
            outlined
            :disabled="disableTarget"
          >
          </v-text-field>

          <v-text-field
            v-else-if="
              target.type &&
              (target.type === 'number' || target.type === 'money')
            "
            v-model.trim="inputTarget"
            @focus="isInputFocused = true"
            @blur="formatAmount"
            :placeholder="target.label || ''"
            hide-details="auto"
            class="mr-2 my-2"
            outlined
            :disabled="(target.value && target.value !== '') || disableTarget"
          >
          </v-text-field>

          <div
            v-else-if="target.type && target.type === 'date'"
            class="mr-2 my-2 mt-6"
          >
            <DateTime
              :placeholder="target.label || ''"
              v-model="form.target"
              :required="true"
              :clearable="true"
            ></DateTime>
          </div>

          <v-text-field
            v-else
            v-model.trim="form.target"
            hide-details="auto"
            class="mr-2 my-2"
            outlined
            :placeholder="target.label || ''"
            :disabled="disableTarget"
          >
          </v-text-field>
        </template>
        <v-text-field
          v-else
          v-model.trim="form.target"
          hide-details="auto"
          class="mr-2 my-2"
          outlined
          :disabled="disableTarget"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="1">
        <v-btn icon>
          <v-icon
            @click="deleteInput"
            class="mx-7"
            id="no-background-hover"
            tag="button"
            plain
            :color="isSDK ? 'grey' : 'error'"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateTime from "@/components/DateTime.vue";
export default {
  components: {
    DateTime,
  },
  props: {
    index: {
      default: -1,
    },
    inputs: {
      default: null,
    },
    value: {
      default: null,
    },

    isSDK: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        field: "",
        type: "",
        target: "",
      },
      isInputFocused: false,
    };
  },
  methods: {
    deleteInput() {
      this.$emit("delete", this.index);
    },

    formatAmount() {
      this.form.target = this.returnFormat(this.form.target);

      this.isInputFocused = false;

      // return amount;
    },

    returnFormat(val) {
      // console.log("returnFormat", val);
      let amount = val;
      amount = amount.replace(/,/g, "");

      if (
        isNaN(parseFloat(amount)) &&
        (this.target.type === "money" || this.target.type === "number")
      ) {
        return "";
      }

      if (amount) {
        amount = this.addDec(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      // console.log("returning...", amount);

      return amount;
    },

    addDec(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (this.target.type === "money") {
        if (target.endsWith(".")) {
          const newV = target + "00";
          val = newV.trim();
        } else if (target.indexOf(".") == -1) {
          const newV = target + ".00";
          val = newV.trim();
        } else {
          // already has dot
          const newV = parseFloat(target).toFixed(2);
          val = newV.trim();
        }
      } else if (this.target.type === "number") {
        val = parseFloat(target).toFixed(0);
      } else {
        val = target;
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (
        dotCount > 1 &&
        (this.target.type === "money" || this.target.type === "number")
      ) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        let condition = val;

        const type = this.target?.type || null;

        // add comma to money and number before comparing to form unless endless loop

        if (type !== null) {
          const preformatedCondition = {
            ...this.form,
            ...((type === "money" || type == "number") && {
              target: this.form.target.replace(/,/g, ""),
            }),
          };

          if (
            JSON.stringify(condition) !== JSON.stringify(preformatedCondition)
          ) {
            // format amount
            this.form = condition.map((c) => {
              return {
                ...c,
                target: this.returnFormat(c.target),
              };
            });
          }
        } else {
          if (JSON.stringify(condition) !== JSON.stringify(this.form)) {
            this.form = condition;
          }
        }
      },
    },
    form: {
      immediate: true,
      deep: true,
      handler(val) {
        let condition = val;

        // remove comma from target before sending to parent
        if (this.target?.type === "money" || this.target?.type === "number") {
          condition.target = condition.target.replace(/,/g, "");

          if (
            JSON.stringify({
              ...this.form,
              target: this.form.target.replaceAll(/,/g, ""),
            }) !== JSON.stringify(this.value)
          ) {
            this.$emit("input", condition);
          }
        } else {
          if (JSON.stringify(this.form) !== JSON.stringify(this.value)) {
            this.$emit("input", condition);
          }
        }
      },
    },

    target: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if (val.value && val.value !== this.form.target) {
            this.form.target = this.returnFormat(val.value);
          }
        }
      },
    },
  },
  computed: {
    target() {
      if (this.form.field) {
        return this.inputs.fields.find(
          (field) => field.key === this.form.field
        );
      } else {
        return null;
      }
    },

    disableTarget() {
      return this.form.type === "NULL" || this.form.type === "NOTNULL";
    },

    inputTarget: {
      get() {
        if (this.target.type === "money" || this.target.type === "number") {
          return this.isInputFocused
            ? this.form.target
            : this.returnFormat(this.form.target);
        } else {
          return this.form.target;
        }
      },
      set(val) {
        this.form.target = val.replace(/,/g, "");
      },
    },
  },
};
</script>

<style scoped></style>
