var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-2 pl-4",staticStyle:{"align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"justify-center my-2 mr-2",staticStyle:{"background":"#ffffff","box-sizing":"border-box","border-radius":"3px"},attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.inputs.fields,"item-text":"label","item-value":"key","flat":"","outlined":"","hide-details":"auto","placeholder":"Select a field"},on:{"change":function () { return (_vm.form.target = ''); }},model:{value:(_vm.form.field),callback:function ($$v) {_vm.$set(_vm.form, "field", $$v)},expression:"form.field"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"justify-center my-2 mr-2",staticStyle:{"box-sizing":"border-box","border-radius":"3px"},style:(_vm.isSDK ? 'background: #F9FAFA' : 'background:  #e8f9f6'),attrs:{"color":"#96EAD","id":"operators","item-value":"key","menu-props":{ bottom: true, offsetY: true },"items":_vm.inputs.operators,"item-text":"label","flat":"","outlined":"","hide-details":"auto"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(_vm.target)?[(
            (_vm.target.type && _vm.target.type === 'dropDown') ||
            _vm.target.type === 'radio' ||
            _vm.target.type === 'checkbox' ||
            (_vm.target.type && _vm.target.type === 'select') ||
            (_vm.target && _vm.target.type === 'checklist') ||
            (_vm.target && _vm.target.type === 'checkgroup')
          )?_c('v-select',{staticClass:"mr-2 my-2",attrs:{"items":_vm.target.options,"item-text":"label","item-value":"value","max-height":"60px","height":"60px","placeholder":_vm.target.label || '',"hide-details":"auto","outlined":"","disabled":_vm.disableTarget},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", $$v)},expression:"form.target"}}):(_vm.target.type && _vm.target.type === 'text')?_c('v-text-field',{staticClass:"mr-2 my-2",attrs:{"placeholder":_vm.target.label || '',"type":"text","hide-details":"auto","outlined":"","disabled":_vm.disableTarget},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.target"}}):(
            _vm.target.type &&
            (_vm.target.type === 'number' || _vm.target.type === 'money')
          )?_c('v-text-field',{staticClass:"mr-2 my-2",attrs:{"placeholder":_vm.target.label || '',"hide-details":"auto","outlined":"","disabled":(_vm.target.value && _vm.target.value !== '') || _vm.disableTarget},on:{"focus":function($event){_vm.isInputFocused = true},"blur":_vm.formatAmount},model:{value:(_vm.inputTarget),callback:function ($$v) {_vm.inputTarget=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputTarget"}}):(_vm.target.type && _vm.target.type === 'date')?_c('div',{staticClass:"mr-2 my-2 mt-6"},[_c('DateTime',{attrs:{"placeholder":_vm.target.label || '',"required":true,"clearable":true},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", $$v)},expression:"form.target"}})],1):_c('v-text-field',{staticClass:"mr-2 my-2",attrs:{"hide-details":"auto","outlined":"","placeholder":_vm.target.label || '',"disabled":_vm.disableTarget},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.target"}})]:_c('v-text-field',{staticClass:"mr-2 my-2",attrs:{"hide-details":"auto","outlined":"","disabled":_vm.disableTarget},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.target"}})],2),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"mx-7",attrs:{"id":"no-background-hover","tag":"button","plain":"","color":_vm.isSDK ? 'grey' : 'error'},on:{"click":_vm.deleteInput}},[_vm._v(" mdi-close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }