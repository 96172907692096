<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/actions-send-email.svg')" />
          <span class="t">Send Email</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span class="action-title">Send Email</span>
        </div>

        <!-- <span class="action-description"
          >Select the co-worker from who approval is required for this
          stage</span
        > -->

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12">
              <v-text-field
                name="Subject"
                label="Subject"
                placeholder="Subject"
                v-model="data.subject"
                hide-details="auto"
                outlined
                primary
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                v-model="data.sendto"
                color="primary"
                :items="sendto"
                hide-details="auto"
                placeholder="Send To"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="data.sendto === 'Team/Co Worker'">
              <v-combobox
                outlined
                color="primary"
                label="To:"
                :items="workers"
                hide-no-data
                height="60px"
                search-input="search"
                v-model="data.to"
                hide-details="auto"
                placeholder="To:"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" v-if="data.sendto === 'Team/Co Worker'">
              <v-combobox
                multiple
                :items="workers"
                name="CC"
                chips
                hide-no-data
                height="60px"
                label="CC"
                v-model="data.cc"
                placeholder="Subject"
                hide-details="auto"
                outlined
                primary
              ></v-combobox>
            </v-col>

            <v-col cols="12" v-if="data.sendto === 'Others'">
              <v-combobox
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'input' || i.type === 'text'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                hide-no-data
                height="60px"
                v-model="othersValue"
                placeholder="@field"
                hide-details="auto"
                outlined
                primary
              ></v-combobox>
            </v-col>

            <v-col cols="12">
              <v-textarea
                name="Message"
                label="Message"
                placeholder="Message"
                v-model="data.message"
                hide-details="auto"
                outlined
                primary
              ></v-textarea>
            </v-col>
          </v-row>
        </div>

        <!-- <span class="note">
          <b>Note:</b> Approval request will be sent to the selected co-worker,
          along with details of this workflow
        </span> -->

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            @click="addToWorkflow"
            :disabled="!canAddToWorkflow"
            large
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        text: "Send Email",
        type: "sendEmail",
        icon: require("@/assets/actions-send-email.svg"),
        active: true,
        meta: {
          type: "hyphenEmail",
          properties: {
            keys: ["subject", "message", "to", "cc", "organization", "name"],
            values: ["", "", "", null, "", ""],
          },
        },
      },
    },

    isSDK: {
      type: Boolean,
      default: false,
    },

    organizationId: {
      type: String,
      default: "",
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      workers: [""],
      sendto: ["Team/Co Worker", "Others"],
      isLoadingWorkers: false,
      data: {
        subject: "",
        to: "",
        cc: null,
        message: "",
        sendto: "Team/Co Worker",
      },
    };
  },
  mounted() {
    this.mapForm();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addToWorkflow() {
      const cc = this.data.cc ? this.data.cc.toString() : "";
      const payload = {
        type: "hyphenEmail",
        properties: {
          keys: [
            "subject",
            "message",
            "to",
            "cc",
            "organization",
            "sendto",
            "name",
          ],
          values: [
            this.data.subject,
            this.data.message,
            this.data.to.key !== undefined
              ? "@" + this.data.to.key
              : this.data.to,
            cc,
            this.organizationId,
            this.data.sendto,
            "email",
          ],
        },
      };
      this.$emit("input", payload);
      this.sendOutChannel();
      this.close();
    },
    mapForm() {
      if (this.value && !this.value?.fresh) {
        // set subject
        this.data.subject =
          this.value.properties.values[
            this.value.properties.keys.indexOf("subject")
          ];
        // set message
        this.data.message =
          this.value.properties.values[
            this.value.properties.keys.indexOf("message")
          ];

        // set to
        var to =
          this.value.properties.values[
            this.value.properties.keys.indexOf("to")
          ] || "";

        if (to.indexOf("@") === 0) {
          to = to.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === to);
          if (input) {
            this.data.to = input;
          } else {
            this.data.to = to;
          }
        } else {
          this.data.to = to;
        }

        if (
          this.value.properties.values[
            this.value.properties.keys.indexOf("cc")
          ] !== null
        ) {
          this.data.cc =
            this.value.properties.values[
              this.value.properties.keys.indexOf("cc")
            ].split(",");
        }

        this.data.sendto =
          this.value.properties.values[
            this.value.properties.keys.indexOf("sendto")
          ] || "Team/Co Worker";
      }
      this.sendOutChannel();
    },
    sendOutChannel() {
      this.$emit(
        "channel",
        typeof this.data.to === "string" ? this.data.to : "@" + this.data.to.key
      );
    },

    async getCoworkers() {
      try {
        this.isLoadingWorkers = true;
        const response = await this.$store.dispatch(
          "organizations/fetchCoWorkers"
        );
        this.workers = response.map((worker) => worker.email);
      } finally {
        this.isLoadingWorkers = false;
      }
    },
  },

  computed: {
    canAddToWorkflow() {
      return this.data.subject && this.data.message && this.data.to;
    },
    coWorkers() {
      return this.$store.getters["organizations/getOrganizationCoWorkers"];
    },

    othersValue: {
      get() {
        return this.data.to;
      },
      set(val) {
        this.data.to = val;
      },
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.mapForm();
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },

    "data.sendto"(val) {
      if (val === "Others") {
        this.data.cc = "";
      } else if (val === "Team/Co Worker") {
        this.data.to = "";
      }
    },

    coWorkers: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length > 0) {
          this.workers = val.map((worker) => worker.email);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
