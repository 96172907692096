var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.value)?_c('v-card',{staticStyle:{"mix-blend-mode":"normal","opacity":"0.8","box-sizing":"border-box","border-radius":"8px","margin-bottom":"10px","margin-top":"10px"},style:(_vm.groupConditions.length > 1 || (_vm.isLast && _vm.groupConditions.length == 1)
        ? 'border:1px solid rgba(127, 145, 155, 0.15);'
        : 'border:none'),attrs:{"flat":"","color":_vm.isSDK
        ? '#F9FAFA'
        : _vm.groupConditions.length > 1 ||
          (_vm.isLast && _vm.groupConditions.length == 1)
        ? '#F8F9FC'
        : 'transparent'}},[_c('div',{staticStyle:{"margin-top":"10px","text-align":"left"}},[(
          _vm.groupConditions.length > 1 ||
          (_vm.isLast && _vm.groupConditions.length == 1)
        )?_c('div',{staticStyle:{"margin-top":"21px","padding-left":"12px","margin-bottom":"9px","font-family":"Inter","font-style":"normal","font-weight":"bold","font-size":"15px","line-height":"15px","text-transform":"uppercase"},style:(_vm.groupConditions.length > 1 ? undefined : 'opacity:1')},[_vm._v(" WITH "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-bold px-4 mx-1",staticStyle:{"padding":"0px 8px","margin":"0 4px","font-size":"16px"},attrs:{"id":"chip","small":"","text-color":"primary","color":"white"}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.group_Type)+" ")])]}}],null,false,1248030302)},[_c('v-card',{staticClass:"p-0",staticStyle:{"padding":"0px"},attrs:{"width":"150px"}},[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.comparisonType),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"padding":"4px 0px"},on:{"click":function($event){_vm.groupType = item.val}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.string))])],1)}),1)],1)],1)],1),_vm._v(" OF THE FOLLOWING ")],1):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"25px"}},_vm._l((_vm.groupConditions),function(rule,i){return _c('div',{key:i},[_c('workflow-condition-input',{staticStyle:{"margin":"8px 0"},attrs:{"rule":rule,"index":i,"isSDK":_vm.isSDK,"inputs":_vm.inputs},on:{"delete":_vm.deleteCondition},model:{value:(_vm.groupConditions[i].properties),callback:function ($$v) {_vm.$set(_vm.groupConditions[i], "properties", $$v)},expression:"groupConditions[i].properties"}})],1)}),0),_c('v-btn',{staticClass:"font-weight-bold mb-3 pl-0 ml-6",staticStyle:{"text-transform":"lowercase","font-size":"16px"},attrs:{"ripple":false,"color":"primary","text":""},on:{"click":_vm.addGroupCondition}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" add new condition")],1)],1):_vm._e(),_c('div',{}),(_vm.isLast)?_c('v-card',{staticClass:"mb-5 mt-11 py-3",staticStyle:{"mix-blend-mode":"normal","opacity":"0.8","border":"1px solid rgba(127, 145, 155, 0.15)","box-sizing":"border-box","border-radius":"8px"},attrs:{"flat":"","color":_vm.isSDK ? '#F9FAFA' : '#F8F9FC'}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","font-family":"Inter","font-style":"normal","font-weight":"bold","line-height":"15px","gap":"10px","text-transform":"uppercase","color":"#96a9cf"},style:({
        fontSize: ("" + (_vm.$vuetify.breakpoint.smAndUp ? '15px' : '10px')),
        flexDirection: ("" + (_vm.$vuetify.breakpoint.smAndUp ? 'row' : 'column')),
      })},[_c('v-btn',{staticClass:"font-weight-bold ml-6 pl-0",staticStyle:{"text-transform":"lowercase","font-size":"16px"},style:({
          fontSize: ("" + (_vm.$vuetify.breakpoint.smAndUp ? '16px' : '10px')),
        }),attrs:{"color":"#96A9CF","text":""},on:{"click":function($event){return _vm.$emit('add-new-group', _vm.newGroup)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" add new group")],1),_vm._v(" WITH "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-bold px-4 mx-3",staticStyle:{"font-size":"16px"},attrs:{"id":"chip","small":"","text-color":"primary"}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.newGroup_Type)+" ")])]}}],null,false,421240487)},[_c('v-card',{staticClass:"p-0",attrs:{"width":"150px"}},[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.comparisonType),function(item,i){return _c('v-list-item',{key:i,staticClass:"py-2",on:{"click":function($event){_vm.newGroup = item.val}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.string))])],1)}),1)],1)],1)],1),_vm._v(" OF THE FOLLOWING ")],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }