<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img
            class="i"
            :src="require('@/assets/actions-send-token.svg')"
            alt=""
          />
          <span class="t">Send Token/Voucher</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span class="action-title">Voucher</span>
        </div>

        <span class="action-description"
          >Set your preference here, and hyphen will automatically generate and
          send a random string/token with the assigned value to your
          customer/team</span
        >

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.email"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) =>
                        i.type === 'input' ||
                        i.type === 'email' ||
                        i.type === 'text'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Recipient Email"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.person"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'input' || i.type === 'text'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Recipient Name"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.value"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter((i) => i.type === 'number')) ||
                  []
                "
                item-text="label"
                @blur="formatAmount"
                item-value="key"
                placeholder="Voucher Value"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.prefix"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'input' || i.type === 'text'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Code Prefix"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-switch v-model="form.send" disabled :label="`Send`"></v-switch>
            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
            :disabled="!canAddToWorkflow"
            @click="addToWorkflow"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenSendToken",
        properties: {
          keys: ["email", "person", "value", "prefix"],
          values: [],
        },
      },
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        email: "",
        person: "",
        value: "",
        prefix: "",
        send: false,
      },
    };
  },
  mounted() {
    this.mapForm();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenSendToken",
        properties: {
          keys: ["email", "person", "value", "prefix", "send", "organization"],
          values: [
            this.form.email.key === undefined
              ? this.form.email
              : "@" + this.form.email.key,
            this.form.person.key === undefined
              ? this.form.person
              : "@" + this.form.person.key,
            this.form.value.key === undefined
              ? this.form.value
              : "@" + this.form.value.key,
            this.form.prefix.key === undefined
              ? this.form.prefix
              : "@" + this.form.prefix.key,
            this.form.send === true ? "true" : "false",
            this.organizationId,
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();
      this.close();
    },

    sendOutChannel() {
      this.$emit(
        "channel",
        this.form.value.key === undefined
          ? this.form.value
          : this.form.value.key
      );
    },

    mapForm() {
      if (this.value) {
        // set email
        var email =
          this.value.properties.values[
            this.value.properties.keys.indexOf("email")
          ] || "";
        if (email.indexOf("@") === 0) {
          email = email.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === email);
          if (input) {
            this.form.email = input;
          } else {
            this.form.email = email;
          }
        } else {
          this.form.email = email;
        }

        // set person
        var person =
          this.value.properties.values[
            this.value.properties.keys.indexOf("person")
          ] || "";

        if (person.indexOf("@") === 0) {
          person = person.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === person);
          if (input) {
            this.form.person = input;
          } else {
            this.form.person = person;
          }
        } else {
          this.form.person = person;
        }

        // set value
        var value =
          this.value.properties.values[
            this.value.properties.keys.indexOf("value")
          ] || "";

        if (value.indexOf("@") === 0) {
          value = value.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === value);
          if (input) {
            this.form.value = input;
          } else {
            this.form.value = value;
          }
        } else {
          this.form.value = value;
        }

        // set prefix
        var prefix =
          this.value.properties.values[
            this.value.properties.keys.indexOf("prefix")
          ] || "";

        if (prefix.indexOf("@") === 0) {
          prefix = prefix.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === prefix);
          if (input) {
            this.form.prefix = input;
          } else {
            this.form.prefix = prefix;
          }
        } else {
          this.form.prefix = prefix;
        }

        // set send
        this.form.send =
          this.value.properties.values[
            this.value.properties.keys.indexOf("send")
          ] === "true"
            ? true
            : false || "";

        this.sendOutChannel();
      }
    },

    formatAmount() {
      let amount = this.form.value.toString();
      amount = amount.replace(/,/g, "");

      if (!isNaN(parseFloat(amount))) {
        amount = this.addKobo(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.form.value = amount;
      }
    },

    addKobo(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (target.endsWith(".")) {
        const newV = target + "00";
        val = newV.trim();
      } else if (target.indexOf(".") == -1) {
        const newV = target + ".00";
        val = newV.trim();
      } else {
        // already has dot
        const newV = parseFloat(target).toFixed(2);
        val = newV.trim();
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (dotCount > 1) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },
  },

  computed: {
    canAddToWorkflow() {
      return (
        this.form.email &&
        this.form.person &&
        this.form.value &&
        this.form.prefix
      );
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
