<template>
  <div>
    <div class="vertical-line"></div>
    <div class="loader" v-if="isLoadingForms">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else class="form-trigger">
      <span class="header" v-if="!isEdit">
        Select a form which entries will trigger this workflow
      </span>

      <span class="header" v-if="isEdit && value"> Selected Form </span>

      <div v-if="isEdit && !value" class="no-form">
        <img :src="require('@/assets/rinfo.svg')" />
        <span>
          The form triggering this workflow is no longer available!.
        </span>
      </div>

      <div class="wrapper">
        <div
          v-if="isEdit ? value : true"
          :style="!$vuetify.breakpoint.smAndDown ? 'max-width: 55%' : undefined"
        >
          <v-select
            outlined
            :disabled="isEdit"
            :items="items"
            item-text="form_title"
            item-value="id"
            v-model="selectedForm"
            color="primary"
            :hint="isEdit ? '' : '* only forms not already in use are visible'"
            persistent-hint
            hide-details="auto"
            placeholder="Select form"
          ></v-select>
        </div>

        <v-btn
          elevation="0"
          @click="proceed"
          :disabled="selectedForm == null"
          :color="isSDK ? '#19283D' : 'primary'"
          :dark="isSDK"
        >
          <v-icon v-if="!isSDK" left>mdi-arrow-down</v-icon>
          Continue
          <v-icon v-if="isSDK" right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    isVisable: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      default: false,
    },
    isSDK: {
      type: Boolean,
      default: false,
    },
    apiKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoadingForms: false,
      selectedForm: null,
      items: [],
      proceeded: false,
    };
  },
  methods: {
    async fetchOrgForms() {
      try {
        this.isLoadingForms = true;
        await this.$store.dispatch("formBuilder/FetchAllForms");
      } catch (err) {
        if (process.env.NODE_ENV === "development") console.log(err);
      } finally {
        this.isLoadingForms = false;
      }
    },

    proceed() {
      this.proceeded = true;
      this.$emit("input", this.selectedForm);
      this.$emit("next");
    },
  },
  computed: {
    allForms() {
      return this.$store.getters["formBuilder/forms"];
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== this.selectedForm && val !== null) {
          console.log("the form idddddddd", val);
          this.selectedForm = parseInt(val);
        }
      },
    },

    isVisable: {
      immediate: true,
      handler(val) {
        if (val && !this.apiKey) {
          this.fetchOrgForms();
        }
      },
    },

    selectedForm() {
      if (this.proceeded) {
        this.$emit("input", null);
      }
    },

    allForms: {
      immediate: true,
      handler(data) {
        if (data) {
          this.items = this.isEdit
            ? data
            : data.filter((form) => !form.has_workflow);
          if (this.apiKey) {
            this.isLoadingForms = false;
          }
        } else {
          if (this.apiKey) {
            this.isLoadingForms = true;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // mobile view
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.vertical-line {
  display: block;
  background-color: #d9dee1;

  margin: auto;
  height: 80px;
  width: 2px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
}

.form-trigger {
  width: 100%;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;

  .no-form {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-left: 4px solid #ff6a6a;
    margin: 30px 0px;
    max-width: 635px;
    gap: 25px;
    background-color: #fff;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #19283d;
    }
  }

  @media (max-width: 600px) {
    padding: 10px;
  }

  .header {
    display: block;
    font-size: 16px;
    color: rgba(25, 40, 61, 0.8);
  }
}
</style>
