var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"650px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"action"},[_c('div',{staticClass:"action__header"},[_c('div',{staticClass:"b"},[_c('img',{staticClass:"i",attrs:{"src":require('@/assets/actions-add-to-payables.svg'),"alt":""}}),_c('span',{staticClass:"t"},[_vm._v("Add To Payble")])]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"action__content",style:(_vm.isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4')},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"action-title"},[_vm._v("PAYABLE")])]),_c('span',{staticClass:"action-description"},[_vm._v("Create a new payable entry using transaction data within the workflow")]),_c('div',{staticClass:"a-wrapper"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(function (i) { return i.type === 'number'; })) ||
                [],"item-text":"label","item-value":"key","placeholder":"Invoice No","outlined":"","hide-details":"auto"},model:{value:(_vm.form.invoice_no),callback:function ($$v) {_vm.$set(_vm.form, "invoice_no", $$v)},expression:"form.invoice_no"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'number' || i.type === 'money'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Amount Due","outlined":"","hide-details":"auto"},model:{value:(_vm.form.amount_due),callback:function ($$v) {_vm.$set(_vm.form, "amount_due", $$v)},expression:"form.amount_due"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Due Date")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"auto","append-icon":"mdi-calendar-blank-outline","outlined":"","readonly":"","value":_vm.dueDateIndays}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.minDate},model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}})],1)],1)],1)],1),_c('div',{staticClass:"bottom"},[_c('v-btn',{attrs:{"large":"","color":"primary","outlined":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel ")],1),_c('v-btn',{attrs:{"large":"","color":_vm.isSDK ? '#19283D' : 'primary',"dark":_vm.isSDK,"elevation":"0","disabled":!_vm.canAddToWorkflow},on:{"click":_vm.addToWorkflow}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" SAVE ")],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }