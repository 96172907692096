<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/actions-money-2.svg')" />
          <span class="t">Transfers</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4'"
      >
        <div class="top">
          <span class="action-title">MAKE TRANSFER</span>
        </div>

        <span class="action-description">
          Initiate a fund transfer from your balance to a wallet of external
          bank account of the same currency

          <br />
          <br />
          <b>
            Use the options below to setup the destination of this transfer
            action.</b
          >
        </span>

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12">
              <v-select
                outlined
                v-model="form.destination"
                color="primary"
                hide-details="auto"
                placeholder="Enter destination"
                :items="destinations"
                item-text="label"
                item-value="key"
              ></v-select>
            </v-col>

            <template v-if="form.destination === 'contact'">
              <v-col cols="6">
                <v-select
                  outlined
                  v-model="form.contact.type"
                  color="primary"
                  hide-details="auto"
                  placeholder="Select contact type"
                  :items="contactTypes"
                  item-text="label"
                  item-value="key"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.contact.value"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Select contact"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </template>

            <template v-if="form.destination === 'wallet'">
              <v-col cols="6">
                <v-select
                  outlined
                  v-model="form.wallet.provider"
                  color="primary"
                  hide-details="auto"
                  placeholder="Select wallet provider"
                  :items="walletProviders"
                  item-text="label"
                  item-value="key"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.wallet.id"
                  color="primary"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  placeholder="Select wallet id"
                  outlined
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </template>
            <template v-if="form.destination === 'bank'">
              <v-col cols="12">
                <v-select
                  v-model="accountDetails"
                  :items="allAccounts"
                  item-text="account_name"
                  item-value="hypn_id"
                  hide-details="auto"
                  class="mr-1"
                  outlined
                >
                </v-select>
              </v-col>
            </template>
          </v-row>
        </div>

        <div class="note">
          <img
            :src="
              isSDK
                ? 'https://embed.hypn.so/images/workflow/invoice-flag.png'
                : require('@/assets/invoice-flag.svg')
            "
          />
          <span>
            <b>Note:</b> Disbursement to this destination will be done
            automatically, we suggest you have an approval action before this
            action in your workflow
          </span>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            @click="addToWorkflow"
            :disabled="!canAddToWorkflow"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      default: {
        type: "hyphenMakeTransfer",
        properties: {
          keys: [
            "destination",
            "contact_type",
            "contact",
            "wallet_provider",
            "wallet",
            "bank",
            "bank_account",
            "organization",
          ],
          values: ["", "", "", "", "", "", "", ""],
        },
      },
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },
    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        destination: "",
        contact: {
          type: "",
          value: "",
        },
        wallet: {
          provider: "",
          id: "",
        },
        bank: {
          value: "",
          account: "",
        },
      },
      destinations: [
        { label: "Contact", key: "contact" },
        { label: "Bank", key: "bank" },
        { label: "Wallet", key: "wallet" },
      ],

      contactTypes: [
        {
          label: "Staff",
          key: "staff",
        },
        {
          label: "Customer",
          key: "customer",
        },
        {
          label: "Vendor",
          key: "vendor",
        },
      ],
      walletProviders: [
        { label: "Hyphen", key: "hyphen" },
        { label: "Woodcore", key: "woodcore" },
      ],
      accountDetails: "",
    };
  },
  mounted() {
    this.mapForm();
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenMakeTransfer",
        properties: {
          keys: [
            "destination",
            "contact_type",
            "contact",
            "wallet_provider",
            "wallet",
            "bank",
            "bank_account",
            "organization",
          ],
          values: [
            this.form.destination,
            this.form.contact.type?.key
              ? "@" + this.form.contact.type.key
              : this.form.contact.type,
            this.form.contact.value?.key
              ? "@" + this.form.contact.value.key
              : this.form.contact.value,
            this.form.wallet.provider?.key
              ? "@" + this.form.wallet.provider.key
              : this.form.wallet.provider,
            this.form.wallet.id?.key
              ? "@" + this.form.wallet.id.key
              : this.form.wallet.id,
            this.form.bank.value,
            this.form.bank.account,
            this.organizationId,
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();

      this.close();
    },

    mapForm() {
      this.form.destination = this.getData("destination");
      this.form.contact.type = this.getData("contact_type");
      this.form.contact.value = this.getData("contact");
      this.form.wallet.provider = this.getData("wallet_provider");
      this.form.wallet.id = this.getData("wallet");
      this.form.bank.value = this.getData("bank");
      this.form.bank.account = this.getData("bank_account");

      this.bindAccountDetails();

      this.sendOutChannel();
    },

    getData(key) {
      var val =
        this.value.properties.values[this.value.properties.keys.indexOf(key)] ||
        "";
      if (val.indexOf("@") === 0) {
        val = val.slice(1);
        // get the object from the inputs
        const input = this.inputs.fields.find((i) => i.key === val);
        if (input) {
          return input;
        } else {
          return val;
        }
      } else {
        return val;
      }
    },

    bindAccountDetails() {
      if (
        this.form.bank.value !== "" &&
        this.allAccounts &&
        this.allAccounts.length > 0 &&
        this.accountDetails === ""
      ) {
        this.accountDetails = this.allAccounts.find(
          (a) => a.hypn_id === this.form.bank.value
        );
      }
    },

    sendOutChannel() {
      this.$emit(
        "channel",

        this.destinations.find((d) => d.key === this.form.destination)?.label ||
          "N/A"
      );
    },
  },
  computed: {
    ...mapGetters({
      AllBanks: "transactions/getAllBanks",
    }),

    allAccounts() {
      return this.$store.getters[
        "organizations/getOrganizationBankAccounts"
      ].filter((a) => a.type === "bank");
    },

    canAddToWorkflow() {
      if (this.form.destination === "") return false;

      if (this.form.destination === "wallet") {
        return this.form.wallet.provider !== "" && this.form.wallet.id !== "";
      } else if (this.form.destination === "contact") {
        return this.form.contact.type !== "" && this.form.contact.value !== "";
      } else {
        return this.form.bank.value !== "" && this.form.bank.account !== "";
      }
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },

    allAccounts: {
      handler(val) {
        if (val && val.length > 0) {
          this.bindAccountDetails();
        }
      },
      deep: true,
      immediate: true,
    },

    accountDetails: {
      handler(val) {
        if (val) {
          const account = this.allAccounts.find((a) => a.hypn_id === val);
          if (account) {
            this.form.bank.value = account.hypn_id;
            this.form.bank.account = account.account_number;
          }
        }
      },
      deep: true,
      immediate: true,
    },

    "form.destination"(val) {
      if (val === "contact") {
        this.form.wallet.provider = "";
        this.form.wallet.id = "";

        this.form.bank.value = "";
        this.form.bank.account = "";
      } else if (val === "wallet") {
        this.form.contact.type = "";
        this.form.contact.value = "";

        this.form.bank.value = "";
        this.form.bank.account = "";
      } else if (val === "bank") {
        this.form.contact.type = "";
        this.form.contact.value = "";

        this.form.wallet.provider = "";
        this.form.wallet.id = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .note {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-left: 4px solid #5b67ba;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    padding: 25px;
    gap: 25px;
    margin: 20px 0px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* or 150% */

      /* hyphen Blue */

      color: #19283d;
    }
  }
}
</style>
