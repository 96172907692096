var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"650px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"action"},[_c('div',{staticClass:"action__header"},[_c('div',{staticClass:"b"},[_c('img',{staticClass:"i",attrs:{"src":require('@/assets/actions-wallet-money.svg')}}),_c('span',{staticClass:"t"},[_vm._v("Request Loan")])]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"action__content",style:(_vm.isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4')},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"action-title"},[_vm._v("LOAN CREATION")])]),_c('span',{staticClass:"action-description"},[_vm._v("Capture expense request automatically before it is approved and added to payables")]),_c('div',{staticClass:"a-wrapper"},[(_vm.step === 1)?[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('v-combobox',{attrs:{"label":"Loan Type","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' || i.type === 'text'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter loan type","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.loanType),callback:function ($$v) {_vm.$set(_vm.data, "loanType", $$v)},expression:"data.loanType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Requester","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'email'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter Requester","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.submitted_by),callback:function ($$v) {_vm.$set(_vm.data, "submitted_by", $$v)},expression:"data.submitted_by"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Principal","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number' ||
                        i.type === 'money'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter Principal","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.principal),callback:function ($$v) {_vm.$set(_vm.data, "principal", $$v)},expression:"data.principal"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Duration","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter duration","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.duration),callback:function ($$v) {_vm.$set(_vm.data, "duration", $$v)},expression:"data.duration"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Duration by","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter duration by","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.durationBy),callback:function ($$v) {_vm.$set(_vm.data, "durationBy", $$v)},expression:"data.durationBy"}})],1)],1)]:_vm._e(),(_vm.step === 2)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Repayment Every","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter repayment every","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.repaymentEvery),callback:function ($$v) {_vm.$set(_vm.data, "repaymentEvery", $$v)},expression:"data.repaymentEvery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Repayment Frequency","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter repayment frequency","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.repaymentFrequency),callback:function ($$v) {_vm.$set(_vm.data, "repaymentFrequency", $$v)},expression:"data.repaymentFrequency"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Interest Type","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number' ||
                        i.type === 'money'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter interest type","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.interestType),callback:function ($$v) {_vm.$set(_vm.data, "interestType", $$v)},expression:"data.interestType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Interest Rate","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'money' ||
                        i.type === 'digits' ||
                        i.type === 'number'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter interest rate","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.interestRate),callback:function ($$v) {_vm.$set(_vm.data, "interestRate", $$v)},expression:"data.interestRate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Number of Repayments","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' ||
                        i.type === 'text' ||
                        i.type === 'digits' ||
                        i.type === 'number' ||
                        i.type === 'money'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter number of repayments","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.numberOfRepayments),callback:function ($$v) {_vm.$set(_vm.data, "numberOfRepayments", $$v)},expression:"data.numberOfRepayments"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"label":"Expected Disbursement Date","type":"text","items":(_vm.inputs &&
                    _vm.inputs.fields.filter(
                      function (i) { return i.type === 'input' || i.type === 'text'; }
                    )) ||
                  [],"item-text":"label","item-value":"key","placeholder":"Enter expected disbursement date","hide-details":"auto","outlined":"","primary":""},model:{value:(_vm.data.expectedDisbursementDate),callback:function ($$v) {_vm.$set(_vm.data, "expectedDisbursementDate", $$v)},expression:"data.expectedDisbursementDate"}})],1)],1)]:_vm._e()],2),_c('div',{staticClass:"bottom"},[_c('v-btn',{attrs:{"large":"","color":"primary","outlined":""},on:{"click":function($event){_vm.step === 1 ? _vm.close : (_vm.step = 1)}}},[(_vm.step === 2)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]):_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.step === 1 ? "Cancel" : "Back")+" ")],1),_c('v-btn',{attrs:{"disabled":_vm.step === 1 ? false : !_vm.canAddToWorkflow,"large":"","color":_vm.isSDK ? '#19283D' : 'primary',"dark":_vm.isSDK,"elevation":"0"},on:{"click":_vm.actnBtn}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(_vm.step === 1 ? "Next" : " Add to FLow")+" ")],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }