<template>
  <div class="trigger">
    <span class="trigger__header">Select Trigger</span>
    <span class="trigger__text">
      Choose the event that begins your workflow, this event acts as the data
      entry point for this workflow
    </span>

    <div class="trigger__content">
      <div
        @click="disabled ? undefined : (selected = trigger.value) && goNext"
        class="option"
        :disabled="disabled"
        v-for="(trigger, index) in sortedTriggers"
        :key="index"
        :class="{ 'option--selected': selected === trigger.value }"
      >
        <img
          :src="trigger.icon"
          :style="isSDK ? 'filter: grayscale(100%)' : undefined"
          :alt="trigger.name"
        />
        <span>{{ trigger.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSDK: {
      type: Boolean,
      default: false,
    },
    triggers: {
      type: Array,
      default: () => ["Form", "Invoice", "Payment"],
    },
  },
  data() {
    return {
      AllTriggers: [
        {
          name: "Form",
          icon: require("@/assets/form-trigger.svg"),
          value: "form",
        },
        {
          name: "Invoice",
          icon: require("@/assets/invoice-trigger.svg"),
          value: "invoice",
        },
        {
          name: "Payment",
          icon: require("@/assets/payment-trigger.svg"),
          value: "payment",
        },
      ],
      selected: null,
    };
  },
  methods: {
    goNext() {
      setTimeout(() => {
        if (this.selected == "invoice") this.$emit("next");
      }, 100);
    },
  },
  computed: {
    sortedTriggers() {
      const triggers = this.triggers.map((trigger) => {
        return trigger.toLowerCase();
      });
      return this.AllTriggers.filter((trigger) =>
        triggers.includes(trigger.name.toLowerCase())
      );
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.newValue !== this.selected) this.selected = newValue;
      },
      deep: true,
      immediate: true,
    },
    selected: {
      handler(newValue) {
        this.$emit("input", newValue);
        this.$store.dispatch("workflow/setTrigger", newValue);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger {
  margin-top: 20px;

  &__header {
    font-weight: bold;
    color: var(--v-primary-base);
    font-size: 16px;
    display: block;
  }

  &__text {
    display: block;
    font-size: 14px;
    color: rgba(25, 40, 61, 0.8);
    margin-top: 10px;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    // mobile view
    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
    grid-gap: 20px;

    margin-top: 40px;
    cursor: pointer;

    .option {
      height: 90px;
      background: #ffffff;
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 10px;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover {
        background-color: #f4f5f6;
      }

      &--selected {
        background-color: #f4f5f6;
      }

      border: 1px solid #d9dee1;
      box-sizing: border-box;
      border-radius: 8px;

      img {
        height: 50px;
        width: 50px;
        object-fit: cover;
      }

      span {
        color: rgba(25, 40, 61, 0.8);
        font-size: 18px;
      }
    }
  }
}
</style>
